.tpk-button {
    align-items: center;
    background: none;
    border: none;
    cursor: pointer;
    display: inline-flex; 
    font-family: "poppins";
    font-size: 1rem;
    font-weight: 600;
    padding: .5rem 1.5rem;
    text-decoration: none;
    transition: background-color .2s ease-in-out;
    justify-content: center;
    &.reverse {
        flex-flow: row-reverse;
        .icon {
            margin-left: 0;
            margin-right: .875rem;
            &::before {
                margin: 0;
            }
            
        }
    }
    &--sm {
        border-radius: 2.5rem;
        height: 2rem;
        padding: .5rem .5rem .5rem .75rem;
        span {
            line-height: 1rem;
        }
        &.reverse {
            padding: .5rem .75rem .5rem .5rem;
        }
    }
    &--md {
        border-radius: 2.5rem;
        height: 2.5rem;
        span {
            line-height: 1.5rem;
        }
    }
    &--lg {
        border-radius: 2.5rem;
        min-height: 3rem;
        span {
            line-height: 2rem;
        }
    }
    .icon {
        margin-left: .875rem;
        &::before {
            margin-left: 0;
        }
        
    }
    &--primary {
        background-color: var(--primary);
        color: var(--primary-text);
        &:hover {
            background-color: var(--primary-hover);
            transition: background-color .2s ease-in-out;
        }
        &.ghost {
            background-color: transparent;
            color: var(--primary);
            border: 1px solid var(--primary);
            opacity: 1;
            transition: opacity .2s ease-in-out;
            &:hover {
                background-color: transparent;
                opacity: .8;
                transition: opacity .2s ease-in-out;
            }
        }
    }
    &--secondary {
        background-color: var(--secondary);
        color: var(--secondary-text);
        &:hover {
            background-color: var(--secondary-hover);
            transition: background-color .2s ease-in-out;
        }
        &.ghost {
            background-color: transparent;
            color: var(--secondary);
            border: 1px solid var(--secondary);
            opacity: 1;
            transition: opacity .2s ease-in-out;
            &:hover {
                background-color: transparent;
                opacity: .8;
                transition: opacity .2s ease-in-out;
            }
        }
    }
    &--tertiary {
        background-color: var(--electric);
        color: var(--electric-text);
        &:hover {
            background-color: var(--electric-hover);
            transition: background-color .2s ease-in-out;
        }
        &.ghost {
            background-color: transparent;
            color: var(--electric);
            border: 1px solid var(--electric);
            opacity: 1;
            transition: opacity .2s ease-in-out;
            &:hover {
                background-color: transparent;
                opacity: .8;
                transition: opacity .2s ease-in-out;
            }
        }
    }

    &:disabled {
        background-color: var(--gray-100);
        color: var(--gray-200);
        cursor: default;
        &:hover {
            background-color: var(--gray-100);
        }
        &.ghost {
            background-color: transparent;
            color: var(--gray-300);
            border: 1px solid var(--gray-300);
        }
    }

    &--link {
        color: var(--primary);
        height: auto;
        padding-left: 0; 
        padding-right: 0;
        &:hover {
            color: var(--primary-hover);
            transition: background-color .2s ease-in-out;
        }
    }

    &--ghost {
        border: 1px solid var(--white);
        color: var(--white);
        &:hover {
            color: var(--gray-50);
            border-color: var(--gray-50);
        }
        &--primary {
            border: 1px solid var(--primary);
            color: var(--primary);
            &:hover {
                color: var(--primary-hover);
                border-color: var(--primary-hover);
            }
        }
    }
    &--app {
        display: inline-flex;
        flex-direction: row;
        padding: .5rem 1rem;
        border-radius: .5rem;
        height: 3rem;
        align-items: center;
        font-weight: 400;
        border: 1px solid var(--white);
        color: var(--white);
        .icon {
            margin-left: 0;
        }
         & > div {
            display: flex;
            flex-direction: column;
            margin-left: .5rem;
            font-size: .75rem;
            line-height: 1rem;
            @media #{$breakpoint-md-up} {
                font-size: 1rem;
                line-height: 1.25rem;
            }
            span {
                text-transform: uppercase;
                font-size: .5rem;
                line-height: .625rem;
                @media #{$breakpoint-md-up} {
                    font-size: .625rem;
                    line-height: .75rem;
                }
            }
        }
        &.dark {
            background-color: var(--base);
            color: var(--white);
            box-shadow: 0.96238px 0.96238px 4.8119px rgba(0, 0, 0, 0.2);
            -moz-box-shadow: 0.96238px 0.96238px 4.8119px rgba(0, 0, 0, 0.2);
            -webkit-box-shadow: 0.96238px 0.96238px 4.8119px rgba(0, 0, 0, 0.2);
            border: none;
        }
    }
}