html,
body {
  color: var(--base);
  font-size: 16px;
  font-family: "poppins", Arial, Helvetica, sans-serif;
  font-display: swap;
  scroll-behavior: smooth;
  &.active,
  &.has-dialog {
    overflow: hidden;
  }
}
.wrapper {
  margin: 0 auto;
  max-width: 80rem;
  width: 100%;
  @media #{$breakpoint-lg-up} {
    max-width: 90rem;
  }
}

a {
  color: var(--primary);
  font-weight: 600;
}
button {
  font-size: 1rem;
  font-family: "poppins", Arial, Helvetica, sans-serif;
}
section .tpk-head {
  padding: 0;
  & > *:last-child {
    margin-bottom: 0;
  }
}
.wpcf7-spinner {
  display: block !important;
}
.t-center {
  text-align: center;
}
.tpk-marker {
  width: 2.25rem;
  height: 2.25rem;
  background-image: url('data:image/svg+xml,<svg width="31" height="36" viewBox="0 0 31 36" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M14.9374 35.7541C12.0369 33.2777 0.600006 22.9778 0.600006 14.5233C0.600006 6.50231 7.31574 0 15.6 0C23.8843 0 30.6 6.50231 30.6 14.5233C30.6 22.9778 19.1632 33.2777 16.2626 35.7541C15.8784 36.082 15.3216 36.082 14.9374 35.7541Z" fill="%23FF7E00"/></svg>');
  background-position: center top;
  background-repeat: no-repeat;
  color: var(--white);
  padding: 7px 0 0px 1px;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  transition: all .3s ease-in-out;
  .icon {
    margin: 0 auto;
    font-size: 0.875rem;
    width: 0.875rem;
    height: 0.875rem;
    display: block;
    transition: all .3s ease-in-out;
    -webkit-animation: bounce-top 0.3s both;
	        animation: bounce-top 0.3s both;
  }
  &.active {
    background-image: url('data:image/svg+xml,<svg width="41" height="48" viewBox="0 0 41 48" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M19.9166 47.6722C16.0491 44.3702 0.800003 30.6371 0.800003 19.3645C0.800003 8.66975 9.75432 0 20.8 0C31.8457 0 40.8 8.66975 40.8 19.3645C40.8 30.6371 25.5509 44.3702 21.6834 47.6722C21.1711 48.1093 20.4289 48.1093 19.9166 47.6722Z" fill="%23202245"/></svg>');
    width: 3rem;
    height: 3rem;
    color: var(--primary);
    padding: 10px 0 0 3px;
    filter: drop-shadow(0px 6px 4px rgba(0, 0, 0, 0.4));
    transition: all .3s ease-in-out;
    -webkit-animation: bounce-top 0.3s both;
	        animation: bounce-top 0.3s both;
    .icon {
      font-size: 1.25rem;
      width: 1.25rem;
      height: 1.25rem;
      transition: all .3s ease-in-out;
    }
  }
}

@-webkit-keyframes bounce-top {
  0% {
    -webkit-transform: translateY(-45px);
            transform: translateY(-45px);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
    opacity: 1;
  }
  24% {
    opacity: 1;
  }
  40% {
    -webkit-transform: translateY(-24px);
            transform: translateY(-24px);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  65% {
    -webkit-transform: translateY(-12px);
            transform: translateY(-12px);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  82% {
    -webkit-transform: translateY(-6px);
            transform: translateY(-6px);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  93% {
    -webkit-transform: translateY(-4px);
            transform: translateY(-4px);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  25%,
  55%,
  75%,
  87% {
    -webkit-transform: translateY(0px);
            transform: translateY(0px);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
  100% {
    -webkit-transform: translateY(0px);
            transform: translateY(0px);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
    opacity: 1;
  }
}
@keyframes bounce-top {
  0% {
    -webkit-transform: translateY(-45px);
            transform: translateY(-45px);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
    opacity: 1;
  }
  24% {
    opacity: 1;
  }
  40% {
    -webkit-transform: translateY(-24px);
            transform: translateY(-24px);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  65% {
    -webkit-transform: translateY(-12px);
            transform: translateY(-12px);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  82% {
    -webkit-transform: translateY(-6px);
            transform: translateY(-6px);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  93% {
    -webkit-transform: translateY(-4px);
            transform: translateY(-4px);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  25%,
  55%,
  75%,
  87% {
    -webkit-transform: translateY(0px);
            transform: translateY(0px);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
  100% {
    -webkit-transform: translateY(0px);
            transform: translateY(0px);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
    opacity: 1;
  }
}

.tpk-spinner {
  display: block;
  width: 4rem;
  height: 4rem;
  margin: 0 auto;
  &__wrapper {
    &.active {
      display: flex;
    }
    position: fixed;
    z-index: 1000000;
    height: 100vh;
    width: 100vw;
    top: 0;
    left: 0;
    display: none;
    align-items: center;
    justify-content: center;
    background-color: var(--white);
  }
}
.tpk-spinner:after {
  content: " ";
  display: block;
  width: 3rem;
  height: 3rem;
  margin: .5rem;
  border-radius: 50%;
  border: .375rem solid #fcf;
  border-color: var(--primary) transparent var(--primary) transparent;
  animation: spinner-dual-ring 1.25s linear infinite;
}
@keyframes spinner-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
