.tpk-footer {
    background-color: var(--base);
    color: var(--white);
    padding: 4rem 1rem 0;
    @media #{$breakpoint-md-up} {
        padding: 4rem 0 0 0;
    }
    &__main {
        border-bottom: 1px solid var(--gray-400);
        @media #{$breakpoint-md-up} {
            padding-bottom: 2rem;
        }
        text-align: center;
        img {
            margin: 0 auto;
        }

        
        @media #{$breakpoint-sm-up} {
            text-align: left;
            img {
                margin: unset;
            }
            .col-xs-12 {
                margin-bottom: 0;
                &:last-of-type {
                    border-top: none;
                    padding-top: unset;
                }
            }
        }
        address {
            margin: 1.5rem 0;
        }
        h4 {
            text-transform: uppercase;
            font-size: 1rem;
            font-weight: 600;
            line-height: 1.25rem;
            margin-bottom: 1.25rem;
        }
        &__nav {
            margin-top: 1.25rem;
            ul {
                list-style: none;
                margin-bottom: 2.5rem;
                margin-left: 0;
                li {
                    font-size: 1rem;
                    line-height: 1.25rem;
                    margin: 0 0 1rem 0;
                    a {
                        color: var(--white);
                        text-decoration: none;
                        font-weight: 400;
                    }
                }
            }
        }
        &__social {
            ul {
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                list-style: none;
                margin: 1.25rem 0;
                justify-content: center;
                @media #{$breakpoint-sm-up} {
                    justify-content: flex-start;
                }
                li {
                    margin: 0 1rem 0 0;
                    a {
                        color: var(--white);
                        text-decoration: none;
                        transition: color 0.2s ease-in-out;
                        &:hover {
                            color: var(--primary);
                            transition: color 0.2s ease-in-out;
                        }
                    }
                }
            }
        }
        &__apps {
            ul {
                list-style: none;
                display: flex;
                flex-direction: column;
                flex-wrap: wrap;
                margin: 1.5rem 0 0 0;
                @media #{$breakpoint-sm-up} {
                    flex-direction: row;
                }
                li {
                    margin: 0 0.5rem 1rem 0;
                    @media #{$breakpoint-sm-up} {
                        margin: 0 1rem 1rem 0;
                    }
                }
            }
        }
        &__certifications {
            ul {
                align-items: center;
                list-style: none;
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                justify-content: center;
                margin-bottom: 3rem;
                margin-left: 0;
                @media #{$breakpoint-sm-up} {
                    justify-content: flex-start;
                }
                li {
                    margin: 0 0.5rem 1rem 0.5rem;
                    @media #{$breakpoint-sm-up} {
                        margin: 0 1rem 1rem 0;
                    }
                }
            }
        }
        &__subnav {
            & > .col-xs-12 {
                padding-bottom: 2.5rem;
                &:last-of-type {
                    border-top: 1px solid var(--gray-400);
                    padding-top: 5rem;
                }
                @media #{$breakpoint-sm-up} {
                    border-top: 0 !important;
                    padding-bottom: 0.25rem;
                    &:last-of-type {                    
                        padding-top: 0;
                    }
                }
            }
            & > div {
            &:nth-child(1) {
                order: 1;
            }
            &:nth-child(2) {
                order: 2; 
                @media #{$breakpoint-sm-up} {
                    order: 3;
                    padding-bottom: 2rem;
                }

                @media #{$breakpoint-md-up} {
                    order: 2;
                }
            }
            &:nth-child(3) {
                order: 3; 
                @media #{$breakpoint-sm-up} {
                    order: 2;
                }

                @media #{$breakpoint-md-up} {
                    order: 3;
                }
            }
        }}
    }
    &__bottom {
        padding: 1rem;
        color: var(--gray-400);
        display: flex;
        flex-direction: column;
        align-items: center;
        @media #{$breakpoint-md-up} {
            padding: 1rem 0rem;
        }
        @media #{$breakpoint-sm-up} {
            flex-direction: row;
            justify-content: space-between;
        }
        &__nav {
            ul {
                list-style: none;
                display: flex;
                flex-direction: column;
                align-items: center;
                margin-bottom: 1rem;
                margin-left: 0;
                text-align: center;
                @media #{$breakpoint-sm-up} {
                    flex-direction: row;
                    align-items: flex-start;
                    margin-bottom: 0;
                }
                li {
                    margin: 0 0 1.5rem 0;
                    @media #{$breakpoint-sm-up} {
                        margin: 0 1.5rem 0 0;
                    }
                    a {
                        color: var(--white);
                        text-decoration: none;
                        font-weight: 400;
                    }
                }
            }
        }
        p {
            margin-bottom: 0;
        }
    }
    &--ora {
        background-color: var(--white);
        color: var(--base);
        padding: 4rem 0 0 0;
        .tpk-footer {
            &__main {
                border-bottom: none;
                .col-xs-12 {
                    padding-bottom: 2rem; 
                    @media #{$breakpoint-md-up} {
                        padding-bottom: 0;
                    }
                    &:last-of-type {
                        border-top: none;
                        @media #{$breakpoint-sm-up} {
                            text-align: right;
                            a {
                                display: inline-block;
                            }
                        }
                    }
                }
                &__social {
                    ul {
                        li {
                            a {
                                color: var(--base);
                            }
                        }
                    }
                    
                }
            }
            &__bottom {
                background-color: var(--gray-300);
                color: var(--white);
                &__nav {
                    margin-right: auto;
                    @media #{$breakpoint-xs-only} {
                        width: 100%;
                    }
                }
                a {
                    color: var(--white);
                }
                p {
                    @media #{$breakpoint-xs-only} {
                        width: 100%;
                        text-align: center;
                    }
                }
            }
        }
    }
}
