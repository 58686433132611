@font-face {
	font-family: "fonticon";
	src: url('../fonts/icons/fonticon.eot');
	src: url('../fonts/icons/fonticon.eot?#iefix') format('eot'),
		url('../fonts/icons/fonticon.woff2') format('woff2');
}
 
  .icon--xs {
		font-size: 1rem;
  }
  .icon--sm {
		font-size: 1.5rem;
  }
  .icon--md {
		font-size: 2rem;
  }
  .icon--lg {
		font-size: 2.5rem;
  }
  .icon--left {
	&:before {
	  float: left;
	  margin-right: .5rem;
	}
  }
  .icon--right {
	&:before {
	  float: right;
	  margin-left: .5rem;
	}
  }
  
@mixin icon-styles {
	&:before {
		display: block;
		width: 1em; 
		height: 1em;
		line-height: 1em;
		font-family: "fonticon";
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		font-style: normal;
		font-variant: normal;
		font-weight: normal;
		// speak: none; // only necessary if not using the private unicode range (firstGlyph option)
		text-decoration: none;
		text-transform: none;
	}
}

.icon {
	@include icon-styles;
}

@function icon-char($filename) {
	$char: "";

	@if $filename == 24h {
		$char: "\E001";
	}
	@if $filename == 2h-cortesia {
		$char: "\E002";
	}
	@if $filename == abiatu {
		$char: "\E003";
	}
	@if $filename == about {
		$char: "\E004";
	}
	@if $filename == account-privacy {
		$char: "\E005";
	}
	@if $filename == account {
		$char: "\E006";
	}
	@if $filename == add {
		$char: "\E007";
	}
	@if $filename == aguas-grises {
		$char: "\E008";
	}
	@if $filename == aguas-negras {
		$char: "\E009";
	}
	@if $filename == airplane {
		$char: "\E00A";
	}
	@if $filename == alert {
		$char: "\E00B";
	}
	@if $filename == apple-pay {
		$char: "\E00C";
	}
	@if $filename == arrow-left {
		$char: "\E00D";
	}
	@if $filename == arrow-right {
		$char: "\E00E";
	}
	@if $filename == battery-charge {
		$char: "\E00F";
	}
	@if $filename == battery-plug {
		$char: "\E010";
	}
	@if $filename == bici-electrica {
		$char: "\E011";
	}
	@if $filename == bike {
		$char: "\E012";
	}
	@if $filename == billing-data {
		$char: "\E013";
	}
	@if $filename == bip-drive {
		$char: "\E014";
	}
	@if $filename == bolt {
		$char: "\E015";
	}
	@if $filename == bono {
		$char: "\E016";
	}
	@if $filename == bottle {
		$char: "\E017";
	}
	@if $filename == bus {
		$char: "\E018";
	}
	@if $filename == cajero-automatico {
		$char: "\E019";
	}
	@if $filename == calendar {
		$char: "\E01A";
	}
	@if $filename == camera {
		$char: "\E01B";
	}
	@if $filename == camping {
		$char: "\E01C";
	}
	@if $filename == cancelar {
		$char: "\E01D";
	}
	@if $filename == car-electric {
		$char: "\E01E";
	}
	@if $filename == car-express {
		$char: "\E01F";
	}
	@if $filename == car-sharing {
		$char: "\E020";
	}
	@if $filename == car-tall {
		$char: "\E021";
	}
	@if $filename == car-width {
		$char: "\E022";
	}
	@if $filename == car {
		$char: "\E023";
	}
	@if $filename == caravan {
		$char: "\E024";
	}
	@if $filename == carrito-bebe {
		$char: "\E025";
	}
	@if $filename == carwash {
		$char: "\E026";
	}
	@if $filename == cctv {
		$char: "\E027";
	}
	@if $filename == charge-plug {
		$char: "\E028";
	}
	@if $filename == charger-type_1 {
		$char: "\E029";
	}
	@if $filename == charger-type_2 {
		$char: "\E02A";
	}
	@if $filename == charger-type_CHAdeMO {
		$char: "\E02B";
	}
	@if $filename == charger-type_css {
		$char: "\E02C";
	}
	@if $filename == charger {
		$char: "\E02D";
	}
	@if $filename == chat-smile {
		$char: "\E02E";
	}
	@if $filename == chat {
		$char: "\E02F";
	}
	@if $filename == check {
		$char: "\E030";
	}
	@if $filename == chevron-down {
		$char: "\E031";
	}
	@if $filename == chevron-left {
		$char: "\E032";
	}
	@if $filename == chevron-right {
		$char: "\E033";
	}
	@if $filename == chevron-up {
		$char: "\E034";
	}
	@if $filename == city {
		$char: "\E035";
	}
	@if $filename == clock {
		$char: "\E036";
	}
	@if $filename == close-fill {
		$char: "\E037";
	}
	@if $filename == close {
		$char: "\E038";
	}
	@if $filename == cloud-off {
		$char: "\E039";
	}
	@if $filename == cobertura-movil {
		$char: "\E03A";
	}
	@if $filename == coffee {
		$char: "\E03B";
	}
	@if $filename == comprobante {
		$char: "\E03C";
	}
	@if $filename == contact {
		$char: "\E03D";
	}
	@if $filename == contactless {
		$char: "\E03E";
	}
	@if $filename == credit-card {
		$char: "\E03F";
	}
	@if $filename == cup {
		$char: "\E040";
	}
	@if $filename == current-location {
		$char: "\E041";
	}
	@if $filename == documento {
		$char: "\E042";
	}
	@if $filename == download {
		$char: "\E043";
	}
	@if $filename == eco-01 {
		$char: "\E044";
	}
	@if $filename == eco {
		$char: "\E045";
	}
	@if $filename == editar {
		$char: "\E046";
	}
	@if $filename == efectivo {
		$char: "\E047";
	}
	@if $filename == elevator {
		$char: "\E048";
	}
	@if $filename == embarazada {
		$char: "\E049";
	}
	@if $filename == entradas-y-salidas {
		$char: "\E04A";
	}
	@if $filename == entrance-authorization {
		$char: "\E04B";
	}
	@if $filename == escaleras-abajo {
		$char: "\E04C";
	}
	@if $filename == escaleras-arriba {
		$char: "\E04D";
	}
	@if $filename == escalon {
		$char: "\E04E";
	}
	@if $filename == export {
		$char: "\E04F";
	}
	@if $filename == eye-off {
		$char: "\E050";
	}
	@if $filename == eye {
		$char: "\E051";
	}
	@if $filename == facebook {
		$char: "\E052";
	}
	@if $filename == faqs {
		$char: "\E053";
	}
	@if $filename == filter-01 {
		$char: "\E054";
	}
	@if $filename == filter {
		$char: "\E055";
	}
	@if $filename == flecha-circulo-abajo {
		$char: "\E056";
	}
	@if $filename == flecha-circulo-arriba {
		$char: "\E057";
	}
	@if $filename == flecha-circulo-derecha {
		$char: "\E058";
	}
	@if $filename == flecha-circulo-izquierda {
		$char: "\E059";
	}
	@if $filename == fumar-no {
		$char: "\E05A";
	}
	@if $filename == fumar {
		$char: "\E05B";
	}
	@if $filename == galibo175 {
		$char: "\E05C";
	}
	@if $filename == galibo180 {
		$char: "\E05D";
	}
	@if $filename == galibo185 {
		$char: "\E05E";
	}
	@if $filename == galibo190 {
		$char: "\E05F";
	}
	@if $filename == galibo195 {
		$char: "\E060";
	}
	@if $filename == galibo200 {
		$char: "\E061";
	}
	@if $filename == galibo210 {
		$char: "\E062";
	}
	@if $filename == galibo215 {
		$char: "\E063";
	}
	@if $filename == galibo220 {
		$char: "\E064";
	}
	@if $filename == galibo225 {
		$char: "\E065";
	}
	@if $filename == galibo230 {
		$char: "\E066";
	}
	@if $filename == galibo235 {
		$char: "\E067";
	}
	@if $filename == galibo240 {
		$char: "\E068";
	}
	@if $filename == galibo245 {
		$char: "\E069";
	}
	@if $filename == galibo250 {
		$char: "\E06A";
	}
	@if $filename == gasolinera {
		$char: "\E06B";
	}
	@if $filename == grid {
		$char: "\E06C";
	}
	@if $filename == historial {
		$char: "\E06D";
	}
	@if $filename == historico {
		$char: "\E06E";
	}
	@if $filename == hombre {
		$char: "\E06F";
	}
	@if $filename == hotel {
		$char: "\E070";
	}
	@if $filename == id {
		$char: "\E071";
	}
	@if $filename == info-circle {
		$char: "\E072";
	}
	@if $filename == info-full {
		$char: "\E073";
	}
	@if $filename == info {
		$char: "\E074";
	}
	@if $filename == instagram {
		$char: "\E075";
	}
	@if $filename == interfono {
		$char: "\E076";
	}
	@if $filename == lavabos {
		$char: "\E077";
	}
	@if $filename == limpieza {
		$char: "\E078";
	}
	@if $filename == link {
		$char: "\E079";
	}
	@if $filename == linkedin {
		$char: "\E07A";
	}
	@if $filename == list-view {
		$char: "\E07B";
	}
	@if $filename == location-fill {
		$char: "\E07C";
	}
	@if $filename == location {
		$char: "\E07D";
	}
	@if $filename == locker {
		$char: "\E07E";
	}
	@if $filename == logout {
		$char: "\E07F";
	}
	@if $filename == mail {
		$char: "\E080";
	}
	@if $filename == map {
		$char: "\E081";
	}
	@if $filename == mastercard-1 {
		$char: "\E082";
	}
	@if $filename == mastercard-2 {
		$char: "\E083";
	}
	@if $filename == mayores {
		$char: "\E084";
	}
	@if $filename == menu {
		$char: "\E085";
	}
	@if $filename == minus {
		$char: "\E086";
	}
	@if $filename == minusvalido {
		$char: "\E087";
	}
	@if $filename == mobile-notify {
		$char: "\E088";
	}
	@if $filename == mobile {
		$char: "\E089";
	}
	@if $filename == moon {
		$char: "\E08A";
	}
	@if $filename == more {
		$char: "\E08B";
	}
	@if $filename == motorbike {
		$char: "\E08C";
	}
	@if $filename == mujer {
		$char: "\E08D";
	}
	@if $filename == multipass {
		$char: "\E08E";
	}
	@if $filename == music {
		$char: "\E08F";
	}
	@if $filename == navigation-fill {
		$char: "\E090";
	}
	@if $filename == navigation {
		$char: "\E091";
	}
	@if $filename == notification {
		$char: "\E092";
	}
	@if $filename == obras {
		$char: "\E093";
	}
	@if $filename == offenses-ok {
		$char: "\E094";
	}
	@if $filename == offenses {
		$char: "\E095";
	}
	@if $filename == paraguas {
		$char: "\E096";
	}
	@if $filename == park-name {
		$char: "\E097";
	}
	@if $filename == park {
		$char: "\E098";
	}
	@if $filename == parkimeter {
		$char: "\E099";
	}
	@if $filename == payment-historic {
		$char: "\E09A";
	}
	@if $filename == payments {
		$char: "\E09B";
	}
	@if $filename == paypal-2 {
		$char: "\E09C";
	}
	@if $filename == peaje {
		$char: "\E09D";
	}
	@if $filename == personal-data {
		$char: "\E09E";
	}
	@if $filename == phone {
		$char: "\E09F";
	}
	@if $filename == plazas-libres {
		$char: "\E0A0";
	}
	@if $filename == precaucion {
		$char: "\E0A1";
	}
	@if $filename == preferences {
		$char: "\E0A2";
	}
	@if $filename == privacy {
		$char: "\E0A3";
	}
	@if $filename == productos {
		$char: "\E0A4";
	}
	@if $filename == promo {
		$char: "\E0A5";
	}
	@if $filename == qr {
		$char: "\E0A6";
	}
	@if $filename == reserva {
		$char: "\E0A7";
	}
	@if $filename == residentes {
		$char: "\E0A8";
	}
	@if $filename == restaurant {
		$char: "\E0A9";
	}
	@if $filename == retry {
		$char: "\E0AA";
	}
	@if $filename == ruler {
		$char: "\E0AB";
	}
	@if $filename == scooter-electric {
		$char: "\E0AC";
	}
	@if $filename == scooter {
		$char: "\E0AD";
	}
	@if $filename == search {
		$char: "\E0AE";
	}
	@if $filename == selector {
		$char: "\E0AF";
	}
	@if $filename == setting {
		$char: "\E0B0";
	}
	@if $filename == shop {
		$char: "\E0B1";
	}
	@if $filename == sin-ticket-2 {
		$char: "\E0B2";
	}
	@if $filename == sin-ticket {
		$char: "\E0B3";
	}
	@if $filename == sm-car {
		$char: "\E0B4";
	}
	@if $filename == solidarity {
		$char: "\E0B5";
	}
	@if $filename == sonangeles {
		$char: "\E0B6";
	}
	@if $filename == star-fill {
		$char: "\E0B7";
	}
	@if $filename == star {
		$char: "\E0B8";
	}
	@if $filename == suelo-resbaladizo {
		$char: "\E0B9";
	}
	@if $filename == supermercado {
		$char: "\E0BA";
	}
	@if $filename == support {
		$char: "\E0BB";
	}
	@if $filename == tag {
		$char: "\E0BC";
	}
	@if $filename == taller {
		$char: "\E0BD";
	}
	@if $filename == tariff {
		$char: "\E0BE";
	}
	@if $filename == taxi {
		$char: "\E0BF";
	}
	@if $filename == telepeaje {
		$char: "\E0C0";
	}
	@if $filename == telpark {
		$char: "\E0C1";
	}
	@if $filename == terms {
		$char: "\E0C2";
	}
	@if $filename == tool {
		$char: "\E0C3";
	}
	@if $filename == train {
		$char: "\E0C4";
	}
	@if $filename == trash {
		$char: "\E0C5";
	}
	@if $filename == truck {
		$char: "\E0C6";
	}
	@if $filename == twitter {
		$char: "\E0C7";
	}
	@if $filename == upload-cloud {
		$char: "\E0C8";
	}
	@if $filename == upload {
		$char: "\E0C9";
	}
	@if $filename == usuario {
		$char: "\E0CA";
	}
	@if $filename == vale-promocional {
		$char: "\E0CB";
	}
	@if $filename == van {
		$char: "\E0CC";
	}
	@if $filename == vehiculo {
		$char: "\E0CD";
	}
	@if $filename == vestuario {
		$char: "\E0CE";
	}
	@if $filename == via-verde {
		$char: "\E0CF";
	}
	@if $filename == viat {
		$char: "\E0D0";
	}
	@if $filename == vip {
		$char: "\E0D1";
	}
	@if $filename == visa-1 {
		$char: "\E0D2";
	}
	@if $filename == visa-2 {
		$char: "\E0D3";
	}
	@if $filename == vouchers-barcode {
		$char: "\E0D4";
	}
	@if $filename == vouchers-qr {
		$char: "\E0D5";
	}
	@if $filename == walk {
		$char: "\E0D6";
	}
	@if $filename == wallet-historic {
		$char: "\E0D7";
	}
	@if $filename == wallet {
		$char: "\E0D8";
	}
	@if $filename == wifi {
		$char: "\E0D9";
	}
	@if $filename == world {
		$char: "\E0DA";
	}
	@if $filename == xxl-car {
		$char: "\E0DB";
	}
	@if $filename == youtube {
		$char: "\E0DC";
	}

	@return $char;
}

@mixin icon($filename, $insert: before, $extend: true) {
	&:#{$insert} {
		@if $extend {
			@extend .icon;
		} @else {
			@include icon-styles;
		}
		content: icon-char($filename);
	}
}

.icon-24h {
	@include icon(24h);
}
.icon-2h-cortesia {
	@include icon(2h-cortesia);
}
.icon-abiatu {
	@include icon(abiatu);
}
.icon-about {
	@include icon(about);
}
.icon-account-privacy {
	@include icon(account-privacy);
}
.icon-account {
	@include icon(account);
}
.icon-add {
	@include icon(add);
}
.icon-aguas-grises {
	@include icon(aguas-grises);
}
.icon-aguas-negras {
	@include icon(aguas-negras);
}
.icon-airplane {
	@include icon(airplane);
}
.icon-alert {
	@include icon(alert);
}
.icon-apple-pay {
	@include icon(apple-pay);
}
.icon-arrow-left {
	@include icon(arrow-left);
}
.icon-arrow-right {
	@include icon(arrow-right);
}
.icon-battery-charge {
	@include icon(battery-charge);
}
.icon-battery-plug {
	@include icon(battery-plug);
}
.icon-bici-electrica {
	@include icon(bici-electrica);
}
.icon-bike {
	@include icon(bike);
}
.icon-billing-data {
	@include icon(billing-data);
}
.icon-bip-drive {
	@include icon(bip-drive);
}
.icon-bolt {
	@include icon(bolt);
}
.icon-bono {
	@include icon(bono);
}
.icon-bottle {
	@include icon(bottle);
}
.icon-bus {
	@include icon(bus);
}
.icon-cajero-automatico {
	@include icon(cajero-automatico);
}
.icon-calendar {
	@include icon(calendar);
}
.icon-camera {
	@include icon(camera);
}
.icon-camping {
	@include icon(camping);
}
.icon-cancelar {
	@include icon(cancelar);
}
.icon-car-electric {
	@include icon(car-electric);
}
.icon-car-express {
	@include icon(car-express);
}
.icon-car-sharing {
	@include icon(car-sharing);
}
.icon-car-tall {
	@include icon(car-tall);
}
.icon-car-width {
	@include icon(car-width);
}
.icon-car {
	@include icon(car);
}
.icon-caravan {
	@include icon(caravan);
}
.icon-carrito-bebe {
	@include icon(carrito-bebe);
}
.icon-carwash {
	@include icon(carwash);
}
.icon-cctv {
	@include icon(cctv);
}
.icon-charge-plug {
	@include icon(charge-plug);
}
.icon-charger-type_1 {
	@include icon(charger-type_1);
}
.icon-charger-type_2 {
	@include icon(charger-type_2);
}
.icon-charger-type_CHAdeMO {
	@include icon(charger-type_CHAdeMO);
}
.icon-charger-type_css {
	@include icon(charger-type_css);
}
.icon-charger {
	@include icon(charger);
}
.icon-chat-smile {
	@include icon(chat-smile);
}
.icon-chat {
	@include icon(chat);
}
.icon-check {
	@include icon(check);
}
.icon-chevron-down {
	@include icon(chevron-down);
}
.icon-chevron-left {
	@include icon(chevron-left);
}
.icon-chevron-right {
	@include icon(chevron-right);
}
.icon-chevron-up {
	@include icon(chevron-up);
}
.icon-city {
	@include icon(city);
}
.icon-clock {
	@include icon(clock);
}
.icon-close-fill {
	@include icon(close-fill);
}
.icon-close {
	@include icon(close);
}
.icon-cloud-off {
	@include icon(cloud-off);
}
.icon-cobertura-movil {
	@include icon(cobertura-movil);
}
.icon-coffee {
	@include icon(coffee);
}
.icon-comprobante {
	@include icon(comprobante);
}
.icon-contact {
	@include icon(contact);
}
.icon-contactless {
	@include icon(contactless);
}
.icon-credit-card {
	@include icon(credit-card);
}
.icon-cup {
	@include icon(cup);
}
.icon-current-location {
	@include icon(current-location);
}
.icon-documento {
	@include icon(documento);
}
.icon-download {
	@include icon(download);
}
.icon-eco-01 {
	@include icon(eco-01);
}
.icon-eco {
	@include icon(eco);
}
.icon-editar {
	@include icon(editar);
}
.icon-efectivo {
	@include icon(efectivo);
}
.icon-elevator {
	@include icon(elevator);
}
.icon-embarazada {
	@include icon(embarazada);
}
.icon-entradas-y-salidas {
	@include icon(entradas-y-salidas);
}
.icon-entrance-authorization {
	@include icon(entrance-authorization);
}
.icon-escaleras-abajo {
	@include icon(escaleras-abajo);
}
.icon-escaleras-arriba {
	@include icon(escaleras-arriba);
}
.icon-escalon {
	@include icon(escalon);
}
.icon-export {
	@include icon(export);
}
.icon-eye-off {
	@include icon(eye-off);
}
.icon-eye {
	@include icon(eye);
}
.icon-facebook {
	@include icon(facebook);
}
.icon-faqs {
	@include icon(faqs);
}
.icon-filter-01 {
	@include icon(filter-01);
}
.icon-filter {
	@include icon(filter);
}
.icon-flecha-circulo-abajo {
	@include icon(flecha-circulo-abajo);
}
.icon-flecha-circulo-arriba {
	@include icon(flecha-circulo-arriba);
}
.icon-flecha-circulo-derecha {
	@include icon(flecha-circulo-derecha);
}
.icon-flecha-circulo-izquierda {
	@include icon(flecha-circulo-izquierda);
}
.icon-fumar-no {
	@include icon(fumar-no);
}
.icon-fumar {
	@include icon(fumar);
}
.icon-galibo175 {
	@include icon(galibo175);
}
.icon-galibo180 {
	@include icon(galibo180);
}
.icon-galibo185 {
	@include icon(galibo185);
}
.icon-galibo190 {
	@include icon(galibo190);
}
.icon-galibo195 {
	@include icon(galibo195);
}
.icon-galibo200 {
	@include icon(galibo200);
}
.icon-galibo210 {
	@include icon(galibo210);
}
.icon-galibo215 {
	@include icon(galibo215);
}
.icon-galibo220 {
	@include icon(galibo220);
}
.icon-galibo225 {
	@include icon(galibo225);
}
.icon-galibo230 {
	@include icon(galibo230);
}
.icon-galibo235 {
	@include icon(galibo235);
}
.icon-galibo240 {
	@include icon(galibo240);
}
.icon-galibo245 {
	@include icon(galibo245);
}
.icon-galibo250 {
	@include icon(galibo250);
}
.icon-gasolinera {
	@include icon(gasolinera);
}
.icon-grid {
	@include icon(grid);
}
.icon-historial {
	@include icon(historial);
}
.icon-historico {
	@include icon(historico);
}
.icon-hombre {
	@include icon(hombre);
}
.icon-hotel {
	@include icon(hotel);
}
.icon-id {
	@include icon(id);
}
.icon-info-circle {
	@include icon(info-circle);
}
.icon-info-full {
	@include icon(info-full);
}
.icon-info {
	@include icon(info);
}
.icon-instagram {
	@include icon(instagram);
}
.icon-interfono {
	@include icon(interfono);
}
.icon-lavabos {
	@include icon(lavabos);
}
.icon-limpieza {
	@include icon(limpieza);
}
.icon-link {
	@include icon(link);
}
.icon-linkedin {
	@include icon(linkedin);
}
.icon-list-view {
	@include icon(list-view);
}
.icon-location-fill {
	@include icon(location-fill);
}
.icon-location {
	@include icon(location);
}
.icon-locker {
	@include icon(locker);
}
.icon-logout {
	@include icon(logout);
}
.icon-mail {
	@include icon(mail);
}
.icon-map {
	@include icon(map);
}
.icon-mastercard-1 {
	@include icon(mastercard-1);
}
.icon-mastercard-2 {
	@include icon(mastercard-2);
}
.icon-mayores {
	@include icon(mayores);
}
.icon-menu {
	@include icon(menu);
}
.icon-minus {
	@include icon(minus);
}
.icon-minusvalido {
	@include icon(minusvalido);
}
.icon-mobile-notify {
	@include icon(mobile-notify);
}
.icon-mobile {
	@include icon(mobile);
}
.icon-moon {
	@include icon(moon);
}
.icon-more {
	@include icon(more);
}
.icon-motorbike {
	@include icon(motorbike);
}
.icon-mujer {
	@include icon(mujer);
}
.icon-multipass {
	@include icon(multipass);
}
.icon-music {
	@include icon(music);
}
.icon-navigation-fill {
	@include icon(navigation-fill);
}
.icon-navigation {
	@include icon(navigation);
}
.icon-notification {
	@include icon(notification);
}
.icon-obras {
	@include icon(obras);
}
.icon-offenses-ok {
	@include icon(offenses-ok);
}
.icon-offenses {
	@include icon(offenses);
}
.icon-paraguas {
	@include icon(paraguas);
}
.icon-park-name {
	@include icon(park-name);
}
.icon-park {
	@include icon(park);
}
.icon-parkimeter {
	@include icon(parkimeter);
}
.icon-payment-historic {
	@include icon(payment-historic);
}
.icon-payments {
	@include icon(payments);
}
.icon-paypal-2 {
	@include icon(paypal-2);
}
.icon-peaje {
	@include icon(peaje);
}
.icon-personal-data {
	@include icon(personal-data);
}
.icon-phone {
	@include icon(phone);
}
.icon-plazas-libres {
	@include icon(plazas-libres);
}
.icon-precaucion {
	@include icon(precaucion);
}
.icon-preferences {
	@include icon(preferences);
}
.icon-privacy {
	@include icon(privacy);
}
.icon-productos {
	@include icon(productos);
}
.icon-promo {
	@include icon(promo);
}
.icon-qr {
	@include icon(qr);
}
.icon-reserva {
	@include icon(reserva);
}
.icon-residentes {
	@include icon(residentes);
}
.icon-restaurant {
	@include icon(restaurant);
}
.icon-retry {
	@include icon(retry);
}
.icon-ruler {
	@include icon(ruler);
}
.icon-scooter-electric {
	@include icon(scooter-electric);
}
.icon-scooter {
	@include icon(scooter);
}
.icon-search {
	@include icon(search);
}
.icon-selector {
	@include icon(selector);
}
.icon-setting {
	@include icon(setting);
}
.icon-shop {
	@include icon(shop);
}
.icon-sin-ticket-2 {
	@include icon(sin-ticket-2);
}
.icon-sin-ticket {
	@include icon(sin-ticket);
}
.icon-sm-car {
	@include icon(sm-car);
}
.icon-solidarity {
	@include icon(solidarity);
}
.icon-sonangeles {
	@include icon(sonangeles);
}
.icon-star-fill {
	@include icon(star-fill);
}
.icon-star {
	@include icon(star);
}
.icon-suelo-resbaladizo {
	@include icon(suelo-resbaladizo);
}
.icon-supermercado {
	@include icon(supermercado);
}
.icon-support {
	@include icon(support);
}
.icon-tag {
	@include icon(tag);
}
.icon-taller {
	@include icon(taller);
}
.icon-tariff {
	@include icon(tariff);
}
.icon-taxi {
	@include icon(taxi);
}
.icon-telepeaje {
	@include icon(telepeaje);
}
.icon-telpark {
	@include icon(telpark);
}
.icon-terms {
	@include icon(terms);
}
.icon-tool {
	@include icon(tool);
}
.icon-train {
	@include icon(train);
}
.icon-trash {
	@include icon(trash);
}
.icon-truck {
	@include icon(truck);
}
.icon-twitter {
	@include icon(twitter);
}
.icon-upload-cloud {
	@include icon(upload-cloud);
}
.icon-upload {
	@include icon(upload);
}
.icon-usuario {
	@include icon(usuario);
}
.icon-vale-promocional {
	@include icon(vale-promocional);
}
.icon-van {
	@include icon(van);
}
.icon-vehiculo {
	@include icon(vehiculo);
}
.icon-vestuario {
	@include icon(vestuario);
}
.icon-via-verde {
	@include icon(via-verde);
}
.icon-viat {
	@include icon(viat);
}
.icon-vip {
	@include icon(vip);
}
.icon-visa-1 {
	@include icon(visa-1);
}
.icon-visa-2 {
	@include icon(visa-2);
}
.icon-vouchers-barcode {
	@include icon(vouchers-barcode);
}
.icon-vouchers-qr {
	@include icon(vouchers-qr);
}
.icon-walk {
	@include icon(walk);
}
.icon-wallet-historic {
	@include icon(wallet-historic);
}
.icon-wallet {
	@include icon(wallet);
}
.icon-wifi {
	@include icon(wifi);
}
.icon-world {
	@include icon(world);
}
.icon-xxl-car {
	@include icon(xxl-car);
}
.icon-youtube {
	@include icon(youtube);
}
