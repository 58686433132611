.tpk-header {
    padding: 5rem 0 0 0;
    position: fixed;
    max-height: 5rem;
    transition: max-height 0.3s ease-in-out;
    width: 100%;
    top: 0;
    transition: all .3s ease-in-out;
    z-index: 200;
    overflow: hidden;
    background-color: var(--white);
    border-bottom: 1px solid var(--gray-200);

    @media #{$breakpoint-md-up} {
        overflow: unset;
    }

    &[aria-expanded="true"] {
        padding: 11.5rem 0 0 0;
        display: flex;
        flex-direction: column;
        flex-flow: column-reverse;
        justify-content: flex-start;
        max-height: 100vh;
        transition: max-height 0.3s ease-in-out;
        overflow: auto;
        z-index: 210;

        &::before {
            display: block;
            content: '';
            width: 100%;
            height: 11.5rem;
            background: white;
            position: fixed;
            top: 0;
            left: 0;
            z-index: 300;
        }

        .tpk-header__button {
            span {

                &:nth-child(1),
                &:nth-child(6) {
                    -webkit-transform: rotate(45deg);
                    -moz-transform: rotate(45deg);
                    -o-transform: rotate(45deg);
                    transform: rotate(45deg);
                }

                &:nth-child(2),
                &:nth-child(5) {
                    -webkit-transform: rotate(-45deg);
                    -moz-transform: rotate(-45deg);
                    -o-transform: rotate(-45deg);
                    transform: rotate(-45deg);
                }

                &:nth-child(1) {
                    top: 15px;
                    right: calc(50% - 2px);
                }

                &:nth-child(2) {
                    top: 15px;
                    left: calc(50% - 2px);
                }

                &:nth-child(3) {
                    right: 100%;
                    opacity: 0;
                }

                &:nth-child(4) {
                    left: 100%;
                    opacity: 0;
                }

                &:nth-child(5) {
                    bottom: 15px;
                    right: calc(50% - 2px);
                }

                &:nth-child(6) {
                    bottom: 15px;
                    left: calc(50% - 2px);
                }
            }

        }

        .tpk-nav {

            &__main,
            &__top,
            &__top--extra {
                opacity: 1 !important;
                transition: opacity 0s ease-in-out 0.3s;
                display: block;
            }

            &__top {
                position: fixed;
                width: 100%;
                top: 8rem;
                border-bottom: 1px solid var(--gray-200);
                z-index: 301;
            }
        }
    }

    @media #{$breakpoint-md-up} {
        padding: 0;
        max-height: unset;
    }

    &__logo {
        position: fixed;
        left: 1rem;
        top: 1.5rem;
        z-index: 301;

        @media #{$breakpoint-md-up} {
            position: relative;
            top: auto;
            left: auto;
            flex: 0 0 auto;
        }
    }

    &--wrapper {
        display: flex;
        flex-direction: column-reverse;
        margin: 0 auto;
        max-width: 90rem;
        width: 100%;

        @media #{$breakpoint-md-up} {
            flex-direction: row;
            justify-content: space-between;
            padding: 0 1rem;
        }
    }

    &__top {
        background-color: var(--white);

        @media #{$breakpoint-md-up} {
            height: 2.5rem;
            border-bottom: 1px solid var(--gray-200);
        }

        a,
        button {
            color: var(--base);
            font-weight: 400;
            line-height: 1.25rem;
            text-decoration: none;
            transition: all 0.2s ease-in-out;

            &:hover {
                color: var(--secondary);
                transition: all 0.2s ease-in-out;
            }

            &.current {
                position: relative;
                font-weight: 600;
                color: var(--base);

                &:after {
                    position: absolute;
                    bottom: -1px;
                    height: 1px;
                    width: 100%;
                    display: block;
                    background-color: var(--primary);
                    content: '';
                }

                @media #{$breakpoint-md-up} {
                    &::after {
                        display: none;
                        ;
                    }
                }
            }
        }

        .tpk-nav__top,
        .tpk-nav__top--extra {
            &>ul {
                align-items: center;
                display: flex;
                flex-direction: row;
                list-style: none;

                li {

                    a,
                    button {
                        align-items: center;
                        display: inline-flex;
                        height: 2.5rem;
                    }
                }
            }
        }

        .tpk-nav__top {
            position: relative;
            opacity: 0;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            margin: 1rem 0 0 0;

            @media #{$breakpoint-md-up} {
                opacity: 1;
                justify-content: flex-start;
                margin: 0;
            }

            p {
                padding: 0 0 0 1rem;
                margin-bottom: 0;

                @media #{$breakpoint-md-up} {
                    padding: .5rem 0;
                }
            }

            ul {
                justify-content: center;
                margin-left: 0;
                margin-bottom: 0;

                li {
                    margin: 0 1rem;

                    @media #{$breakpoint-md-up} {
                        margin: 0 2.5rem 0 0;
                    }

                    a {
                        color: var(--gray-400);

                        &.current {
                            color: var(--base);
                        }
                    }
                }
            }

            &__lang {
                @media #{$breakpoint-md-up} {
                    min-width: 5rem;
                    height: 2.5rem;
                }

                ul {
                    list-style: none;
                    margin-left: 0;

                    @media #{$breakpoint-md-up} {
                        max-height: 2.375rem;
                        overflow: hidden;
                        position: absolute;
                        top: 0;
                        transition: all 0.2s ease-in-out;
                        display: flex;
                        flex-direction: column;
                        border-top: 0;
                        z-index: 1;
                    }

                    li {
                        align-items: center;
                        display: inline-flex;
                        margin: 0 0 0 1.5rem;

                        @media #{$breakpoint-md-up} {
                            margin: 0;
                        }

                        .icon {
                            display: none;

                            @media #{$breakpoint-md-up} {
                                margin-left: 0.5rem;
                                display: block;
                            }
                        }

                        a,
                        button {
                            width: 100%;
                            color: var(--gray-400);

                            @media #{$breakpoint-md-up} {
                                padding: 0 1rem;
                            }
                        }

                        &:first-of-type {
                            a {
                                color: var(--base);
                                font-weight: 600;
                            }
                        }
                    }

                    &:hover {
                        max-height: 20rem;
                        transition: all 0.2s ease-in-out;

                        a,
                        button {
                            background-color: var(--gray-50);
                            transition: background-color 0.2s ease-in-out;

                            &:hover {
                                transition: background-color 0.2s ease-in-out;
                                background-color: var(--gray-100);
                            }
                        }
                    }
                }
            }
        }

        .tpk-nav__top--extra {
            opacity: 0;
            display: none;

            @media #{$breakpoint-md-up} {
                opacity: 1;
                display: block;
            }

            &>ul {
                flex-direction: column;
                align-items: flex-start;
                padding: 0;
                margin-left: 0;
                margin-bottom: 0;

                @media #{$breakpoint-md-up} {
                    flex-direction: row;
                    padding: 0;
                }

                &>li {
                    margin: 0;
                    width: 100%;
                    padding: 0.5rem 1rem;
                    border-bottom: 1px solid var(--gray-200);

                    @media #{$breakpoint-md-up} {
                        border-right: 1px solid var(--gray-100);
                        border-bottom: 0;
                        padding: 0 1rem;
                        width: auto;
                    }

                    &:nth-last-of-type(-n + 2) {
                        position: fixed;
                        left: 1rem;
                        top: 5rem;
                        z-index: 301;
                        width: auto;
                        border-bottom: 0;
                        padding: 0;

                        @media #{$breakpoint-md-up} {
                            position: relative;
                            top: unset;
                            left: unset;
                            right: unset;
                            padding: 0 1rem;
                        }
                    }

                    &:nth-last-of-type(-n + 1) {
                        border-right: none;
                        padding: 0;
                        position: fixed;
                        right: 1rem;
                        top: 5rem;
                        z-index: 301;
                        width: auto;
                        border-bottom: 0;
                        left: unset;

                        @media #{$breakpoint-md-up} {
                            position: relative;
                            top: unset;
                            left: unset;
                            right: unset;
                            padding: inherit;
                        }
                    }
                }
            }
        }
    }

    &__main {
        padding-bottom: 1.5rem;

        @media #{$breakpoint-md-up} {
            height: 5rem;
            padding-bottom: 0;
        }

        .wrapper {
            display: flex;
            flex-direction: column;
            height: 100%;

            @media #{$breakpoint-md-up} {
                flex-direction: row;
                align-items: center;
                padding: 0 1rem;
            }

            .tpk-nav__main {
                width: 100%;
                margin: 2rem 0 0 0;
                opacity: 0;

                @media #{$breakpoint-md-up} {
                    margin: 0 0 0 8.5rem;
                    opacity: 1;
                }

                ul {
                    list-style: none;
                    display: flex;
                    flex-direction: column;
                    margin-left: 0;
                    margin-bottom: 0;
                    position: relative;
                    overflow: hidden;

                    @media #{$breakpoint-md-up} {
                        flex-direction: row;
                        overflow: unset;
                        max-height: 5rem;
                    }

                    li {
                        margin-right: 0;
                        margin-bottom: 0;
                        background-color: var(--white);

                        @media #{$breakpoint-md-up} {
                            margin-right: 2.5rem;
                            margin-bottom: 0;
                            padding: 1.25rem 0;
                        }

                        @media only screen and (min-width: 65rem) and (max-width: 71rem) {
                            margin-right: 1.5rem;
                        }

                        a,
                        button {
                            display: flex;
                            align-items: center;
                            font-size: 1rem;
                            line-height: 1.25rem;
                            padding: 1rem;
                            position: relative;
                            text-decoration: none;
                            font-weight: 400;
                            color: var(--base);
                            border: none;
                            border-bottom: 1px solid var(--gray-200);
                            background: transparent;
                            font-family: inherit;
                            cursor: pointer;
                            width: 100%;
                            text-align: left;

                            @media #{$breakpoint-md-up} {
                                padding: 0.5rem 0;
                                border-bottom: none;
                                text-align: center;
                            }

                            span {
                                margin: 0 .25rem;
                            }

                            &:hover {
                                &:after {
                                    content: "";
                                    height: 100%;
                                    width: 2px;
                                    background-color: var(--gray-200);
                                    display: block;
                                    position: absolute;
                                    left: 0;
                                    top: 0;

                                    @media #{$breakpoint-md-up} {
                                        width: 100%;
                                        height: 2px;
                                        bottom: 0;
                                        top: auto;
                                    }
                                }
                            }

                            &.current {
                                font-weight: 600;

                                &:after {
                                    content: "";
                                    height: 100%;
                                    width: 2px;
                                    background-color: var(--primary);
                                    display: block;
                                    position: absolute;
                                    left: 0;
                                    top: 0;

                                    @media #{$breakpoint-md-up} {
                                        width: 100%;
                                        height: 2px;
                                        bottom: 0;
                                        top: auto;
                                    }
                                }
                            }
                        }

                        ul {
                            background-color: var(--primary);
                            max-height: 0;
                            transition: max-height .3s ease-in-out .2s;

                            li {
                                padding: 0;
                                height: 0;
                                overflow: hidden;

                                a,
                                button {
                                    color: var(--white);
                                    background-color: var(--primary);
                                    line-height: 1.5rem;
                                    border-color: var(--base);

                                    @media #{$breakpoint-md-up} {
                                        opacity: 0;
                                        transition: opacity .2s ease-in-out;
                                    }

                                    &.current,
                                    &:hover {
                                        color: var(--base);

                                        &::after {
                                            display: none;
                                        }
                                    }
                                }
                            }

                            @media #{$breakpoint-md-up} {
                                position: absolute;
                                left: 0;
                                top: 100%;
                                display: flex;
                                align-items: center;

                                &:before {
                                    content: '';
                                    display: block;
                                    height: 100%;
                                    width: 200vw;
                                    background-color: var(--primary);
                                    position: absolute;
                                    left: -50vw;
                                }

                            }
                        }

                        &.active {
                            &>button {
                                span {
                                    transform: rotate(-180deg);
                                }
                            }

                            ul {
                                max-height: 100rem;
                                transition: max-height .3s ease-in-out;

                                @media #{$breakpoint-md-up} {
                                    max-height: 4rem;
                                }

                                li {
                                    height: auto;
                                    background-color: transparent;

                                    a,
                                    button {
                                        opacity: 1;
                                        transition: opacity .3s ease-in-out .3s;
                                    }
                                }
                            }
                        }
                    }
                }

                &--extra {
                    width: 100%;
                    padding: 0 1rem;
                    margin: 2rem 0 0 0;

                    @media #{$breakpoint-md-up} {
                        margin: 0;
                        margin-left: auto;
                        width: auto;
                        padding: 0;
                    }

                    ul {
                        list-style: none;
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        justify-content: space-between;
                        margin-left: 0;
                        margin-bottom: 0;

                        @media #{$breakpoint-md-up} {
                            justify-content: flex-end;
                        }

                        li {
                            margin-bottom: 0;

                            @media #{$breakpoint-md-up} {
                                margin-left: 1.5rem;
                            }

                            .tpk-button {
                                white-space: nowrap;
                                display: none;

                                @media #{$breakpoint-md-up} {
                                    display: inline-flex;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    &__button {
        background-color: transparent;
        border: none;
        display: block;
        width: 2.5rem;
        height: 2.5rem;
        position: fixed;
        right: 1rem;
        top: 1.25rem;
        z-index: 301;

        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
        -webkit-transition: 0.5s ease-in-out;
        -moz-transition: 0.5s ease-in-out;
        -o-transition: 0.5s ease-in-out;
        transition: 0.5s ease-in-out;
        cursor: pointer;

        @media #{$breakpoint-md-up} {
            display: none;
            position: relative;
            top: auto;
            right: auto;
        }

        span {
            display: block;
            display: block;
            position: absolute;
            height: 2px;
            width: 0.75rem;
            background: var(--base);
            opacity: 1;
            -webkit-transform: rotate(0deg);
            -moz-transform: rotate(0deg);
            -o-transform: rotate(0deg);
            transform: rotate(0deg);
            -webkit-transition: 0.25s ease-in-out;
            -moz-transition: 0.25s ease-in-out;
            -o-transition: 0.25s ease-in-out;
            transition: 0.25s ease-in-out;

            &:nth-child(even) {
                left: 50%;
                border-radius: 0 2px 2px 0;
            }

            &:nth-child(odd) {
                right: 50%;
                border-radius: 2px 0 0 2px;
            }

            &:nth-child(1),
            &:nth-child(2) {
                top: 0.625rem;
            }

            &:nth-child(3),
            &:nth-child(4) {
                top: calc(50% - 1px);
            }

            &:nth-child(5),
            &:nth-child(6) {
                bottom: 0.625rem;
            }
        }

        &--app {
            position: fixed;
            z-index: 301;
            right: 4.5rem;
            top: 1rem;

            @media #{$breakpoint-md-up} {
                display: none;
                position: relative;
                top: auto;
                right: auto;
            }
        }
    }

    &+main {
        padding-top: 5rem;

        @media #{$breakpoint-md-up} {
            padding-top: 7.5rem;
        }
    }

    &.scrolled {
        @media #{$breakpoint-md-up} {
            top: -2.5rem;
            transition: all .3s ease-in-out;
        }
    }

    &--empark {
        .tpk-header__main {
            .wrapper {
                .tpk-nav__main--extra {
                    ul {
                        li {
                            .tpk-button {
                                display: inline-flex;
                            }
                        }
                    }
                }
            }
        }
    }
}