:root {
    --base: #202245;
    --white: #fff;
    --primary: #FF9903;
    --primary-hover: #FF7E00;
    --primary-text: #fff;
    --orange-dark: #FF9903;
    --orange-dark-hover: #FFB057;
    --secondary: #202245;
    --secondary-hover: #171826;
    --secondary-text: #fff;
    --electric: #7EDBFC;
    --electric-hover: #0693E3;
    --electric-text: #202245;
    
    --gray-50: #FAFAFB;
    --gray-100: #F1F1F5;
    --gray-200: #D1D3D5;
    --gray-300: #B5B5BF;
    --gray-400: #868D99;
    --gray-500: #667280;
    --gray-600: #545A67;
    --gray-700: #3E3E4A;
    --gray-800: #2D2D3A;
    --gray-900: #171826;

    --red: #CA2933;
    --green: #7FBE00;
    --yellow: #CCA024;
    --red-light: #FCDEE0;
    --green-light: #E9EFDC;
    --yellow-light: #FFF1CA;
}
.electric {
    --primary: #7EDBFC;
    --primary-hover: #0693E3;
    --primary-text: #202245;
}
// Variables para Grid
// Vamos a tomar como medida 1rem el tamaño del texto configurado para el body, en el fichero  ../base/_base.scss.  En este caso 1rem = 16px;

// Grid
$grid-columns: 12 !default; // Número de columnas en  el grid

$xs-max: 40rem !default; // Max width xs breakpoint
$sm-max: 64rem !default; // Max width sm breakpoint
$md-max: 90rem !default; // Max width md breakpoint
$lg-max: 120rem !default; // Max width lg breakpoint

$gutter: 1rem !default; // Set gutter size

$content-well-max-width: "none" !default; // Set the max-width of the content well

$sm-start: ($xs-max + 1); // Generate sm breakpoint's min width
$md-start: ($sm-max + 1); // Generate md breakpoint's min width
$lg-start: ($md-max + 1); // Generate lg breakpoint's min width
$xl-start: ($lg-max + 1); // Generate xl breakpoint's min width

// Breakpoints
// Create breakpoint range statements to be used in media queries
$breakpoint-xs-only: "only screen and (max-width: #{$xs-max})"; // 0 -> xs-max range
$breakpoint-sm-up: "only screen and (min-width: #{$sm-start})"; // sm-start -> up range
$breakpoint-sm-only: "only screen and (min-width: #{$sm-start}) and (max-width: #{$sm-max})"; // sm-start -> sm-max range
$breakpoint-md-up: "only screen and (min-width: #{$md-start})"; // md-start -> up range
$breakpoint-md-only: "only screen and (min-width: #{$md-start}) and (max-width: #{$md-max})"; // md-start -> md-max range
$breakpoint-lg-up: "only screen and (min-width: #{$lg-start})"; // lg-start -> up range
$breakpoint-lg-only: "only screen and (min-width: #{$lg-start}) and (max-width: #{$lg-max})"; // lg-start -> lg-max range
$breakpoint-xl-up: "only screen and (min-width: #{$xl-start})"; // xl-start -> up range

$breakpoints-all: ($breakpoint-xs-only, $breakpoint-sm-up, $breakpoint-sm-only, $breakpoint-md-up, $breakpoint-md-only, $breakpoint-lg-up, $breakpoint-lg-only, $breakpoint-xl-up);
$breakpoint-ups: ($breakpoint-sm-up, $breakpoint-md-up, $breakpoint-lg-up, $breakpoint-xl-up);
$breakpoint-all-prefixes: ("xs", "xs-only", "sm", "sm-only", "md", "md-only", "lg", "lg-only", "xl");
$breakpoint-up-prefixes: ("xs", "sm", "md", "lg", "xl");
