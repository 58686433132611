strong,
.strong,
b {
  font-weight: 600;
}

blockquote cite {
  display: block;
  text-align: right;
  font-weight: 600;
  font-size: 1.5rem;
  font-style: normal;
  line-height: 1;
}

code {
  display: inline-block;
}

ul {
  list-style-type: disc;
}

ul ul {
  list-style-type: disc;
}

ol {
  list-style-type: decimal;
}

ol ol {
  list-style-type: lower-alpha;
}

figure,
.wp-block-image {
  margin: 1rem 0;
}

.article-content {
  padding-top: 4rem;
}

.wp-embed-aspect-16-9 .wp-block-embed__wrapper {
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 1.5rem;
  height: 0;
}

.wp-embed-aspect-16-9 .wp-block-embed__wrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.twitter-tweet {
  margin: 1rem auto;
}

.form-message {
  padding: 1rem 1.5rem;
  border: 1px solid;
  border-radius: 2px;
}

.form-message p {
  margin: 0;
  font-size: 0.75rem;
  font-weight: 400;
}

:not(:defined) {
  display: none;
}

.aligncenter,
div.aligncenter {
  display: block;
  margin: 0 auto;
}

.alignright {
  float: right;
  margin-right: 0;
  margin-left: 4rem;
  margin-bottom: 2rem;
}

.alignleft {
  float: left;
  margin-left: 0;
  margin-right: 4rem;
  margin-bottom: 2rem;
}

.aligncenter {
  display: block;
  margin: 0 auto 2rem;
}

@media only screen and (max-width: 480px) {

  .alignright,
  .alignleft {
    float: none;
    margin-bottom: 2rem;
    margin-right: 0;
    margin-left: 0;
  }
}

img {
  max-width: 100%;
  height: auto;
}

a img.alignright {
  float: right;
  margin-right: 0;
}

a img.alignleft {
  float: left;
  margin-left: 0;
}

a img.aligncenter {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.wp-caption {
  background: #fff;
  border: 1px solid #f0f0f0;
  max-width: 96%;
  /* Image does not overflow the content area */
  text-align: center;
}

.wp-caption img {
  border: 0 none;
  height: auto;
  margin: 0;
  max-width: 100%;
  padding: 0;
  width: auto;
}

figcaption {
  margin-top: 0.5rem;
  font-size: 0.875rem;
  line-height: 1.125rem;
  color: var(--gray-400);
}

img,
picture,
figure {
  &.alignright {
    float: right;
    margin-right: 0;
  }

  &.alignleft {
    float: left;
    margin-left: 0;
  }

  &.aligncenter {
    display: block;
    margin-left: auto;
    margin-right: auto;
  }

  &.alignwide &.alignnone {
    @media #{$breakpoint-sm-up} {
      margin-left: -8.3333333333333%;
      margin-right: -8.3333333333333%;
      max-width: calc(100% + 16.666666667%);
      margin-top: 3rem;
      margin-bottom: 3rem;
    }

    @media #{$breakpoint-md-up} {
      margin-left: -16.666666667%;
      margin-right: -16.666666667%;
      max-width: calc(100% + 33.333333333333%);
      margin-top: 5rem;
      margin-bottom: 5rem;
    }
  }
}

iframe {
  max-width: 100%;
}
