@mixin spacing() {
    @each $prop, $abbrev in (margin: m, padding: p) {
      @each $size, $lengths in $spacers {
        $length-x:   map-get($lengths, x);
        $length-y:   map-get($lengths, y);
        
        @if not($abbrev == p) or not($size == a) {
          .#{$abbrev}-#{$size} { #{$prop}: $length-y $length-x !important; } // a = All sides
          .#{$abbrev}t-#{$size} { #{$prop}-top:    $length-y !important; }
          .#{$abbrev}r-#{$size} { #{$prop}-right:  $length-x !important; }
          .#{$abbrev}b-#{$size} { #{$prop}-bottom: $length-y !important; }
          .#{$abbrev}l-#{$size} { #{$prop}-left:   $length-x !important; }
        }
      }
    }
}
