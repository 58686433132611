@font-face {
  font-family: "poppins";
  src: url("../fonts/poppins-extrabold.woff2") format("woff2");
  font-display: swap;
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: "poppins";
  src: url("../fonts/poppins-extrabolditalic.woff2") format("woff2");
  font-display: swap;
  font-weight: 800;
  font-style: italic;
}

@font-face {
  font-family: "poppins";
  src: url("../fonts/poppins-italic.woff2") format("woff2");
  font-display: swap;
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: "poppins";
  src: url("../fonts/poppins-regular.woff2") format("woff2");
  font-display: swap;
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "poppins";
  src: url("../fonts/poppins-semibold.woff2") format("woff2");
  font-display: swap;
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "poppins";
  src: url("../fonts/poppins-semibolditalic.woff2") format("woff2");
  font-display: swap;
  font-weight: 600;
  font-style: italic;
}

h1 {
  font-size: 2.5rem;
  font-weight: 600;
  line-height: 3rem;
  margin-bottom: 1rem;
  @media #{$breakpoint-md-up} {
    font-size: 3rem;
    line-height: 3.5rem;
  }
}
h2 {
  font-size: 2rem;
  font-weight: 600;
  line-height: 2.875rem;
  margin-bottom: 1rem;
  @media #{$breakpoint-md-up} {
    font-size: 2.5rem;
    line-height: 3rem;
  }
}
h3 {
  font-size: 1.5rem;
  font-weight: 600;
  line-height: 1.875rem;
  margin-bottom: 1rem;
  @media #{$breakpoint-md-up} {
    font-size: 2rem;
    line-height: 2.875rem;
  }
}
h4 {
  font-size: 1.25rem;
  font-weight: 600;
  line-height: 1.5rem;
  margin-bottom: 1rem;
  @media #{$breakpoint-md-up} {
    font-size: 1.5rem;
    line-height: 1.875rem;
  }
}
h5 {
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.25rem;
  margin-bottom: 1rem;
  @media #{$breakpoint-md-up} {
    font-size: 1.25rem;
    line-height: 1.5rem;
  }
}
h6 {
  font-size: .875rem;
  font-weight: 600;
  line-height: 1rem;
  margin-bottom: 1rem;
  @media #{$breakpoint-md-up} {
    font-size: .875rem;
    line-height: 1rem;
  }
}
p {
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.25rem;
  margin-bottom: 1rem;
  a {
    font-weight: 400;
  }
}
ul,
ol {
  margin-left: 1rem;
  margin-bottom: 2rem;
  li {
    font-size: 1rem;
    line-height: 1.75rem;
  }
  ul,
  ol {
    margin-bottom: 0;
  }
}
em {
  font-style: italic;
}
.small {
  font-size: 0.6125rem;
  font-weight: 400;
  line-height: 0.875rem;
}

.note {
  font-size: 0.75rem;
  line-height: 0.875rem;
  color: var(--gray-400);
}

blockquote {
  padding: 1rem 0 1rem 1rem;
  border-left: 4px solid var(--primary);
  font-size: 1.25rem;
  line-height: 1.5rem;
  margin: 2rem 0 2rem 1rem;
  @media #{$breakpoint-md-up} {
    padding: 1.25rem 0 1.25rem 2rem;
    font-size: 1.25rem;
    line-height: 1.5rem;
    margin: 3rem 0 3rem;
  }
  p, ul, ol {
    font-size: 1.25rem;
    line-height: 1.5rem;
  }
}

table {
  max-width: 100%;
  font-size: 1rem;
  line-height: 1.25rem;
  overflow: auto;
  display: block;
  margin-bottom: 2rem;
  th {
    text-align: left;
    font-weight: 600;
    background-color: var(--gray-50);
    padding: 1rem;
  }
  td {
    padding: 1rem;
    border-bottom: 1px solid var(--gray-200);
  }
}
